import logo from './logo.svg';
import './App.css';
import GameGrid from './components/Grid';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = 'Genius Casino'
  }, [])
  return (
    <div className='w-full'>
      <header className='bg-gray-800 text-white text-center p-4'>
        <h1 className='text-2xl'>Genius Casino</h1>
      </header>
      <div className='container mx-auto p-4'>
        <GameGrid />
      </div>
    </div>
  );
}

export default App;
