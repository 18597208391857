import axios from "axios";
import { useState } from "react";
import sha1 from "crypto-js/sha1";

// {
//     "id": "<game id>", # integer
//     "title": "<game title>", # string
//     "platform": "<platform>", # string
//     "type": "<game type>", # string
//     "enabled": "<1|0>", # integer
//     "fun_mode": "<1|0>", # integer, indicates fun/demo mode support per game
//     "vendor": "<vendor>", # string
//     "details": {
//         "description": "...", # string
//         "some_other_key": "..." # mixed
//     }
// }

export default function GameGrid() {
  const [games, setGames] = useState([
    {
        "id": 1,
        "title": "Apocalypse Knights",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/1.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/1.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/1.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/1.gif"
            },
            "tags": [
                "expandingwild"
            ],
            "rtp": 96.39,
            "volatility": "2.5"
        }
    },
    {
        "id": 2,
        "title": "Aztec Temple",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/2.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/2.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/2.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/2.gif"
            }
        }
    },
    {
        "id": 3,
        "title": "Caesars Glory",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/3.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/3.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/3.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/3.gif"
            }
        }
    },
    {
        "id": 4,
        "title": "Cyber Ninja",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/4.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/4.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/4.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/4.gif"
            }
        }
    },
    {
        "id": 11,
        "title": "Kleopatra",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/11.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/11.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/11.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/11.gif"
            }
        }
    },
    {
        "id": 13,
        "title": "Monsters House",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/13.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/13.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/13.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/13.gif"
            }
        }
    },
    {
        "id": 15,
        "title": "Naga King",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/15.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/15.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/15.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/15.gif"
            }
        }
    },
    {
        "id": 18,
        "title": "Sakura",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/18.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/18.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/18.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/18.gif"
            }
        }
    },
    {
        "id": 22,
        "title": "The Dragon",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/22.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/22.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/22.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/22.gif"
            }
        }
    },
    {
        "id": 27,
        "title": "Venice Carnival",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/27.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/27.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/27.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/27.gif"
            }
        }
    },
    {
        "id": 29,
        "title": "Zombie Escape",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/29.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/29.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/29.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/29.gif"
            }
        }
    },
    {
        "id": 30,
        "title": "Dojo",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/30.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/30.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/30.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/30.gif"
            }
        }
    },
    {
        "id": 34,
        "title": "Legend Of Emerald",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/34.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/34.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/34.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/34.gif"
            }
        }
    },
    {
        "id": 40,
        "title": "Horror Circus",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/40.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/40.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/40.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/40.gif"
            }
        }
    },
    {
        "id": 42,
        "title": "Kunoichi",
        "platform": "desktop-and-mobile",
        "type": "slots",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/42.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/42.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/42.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/42.gif"
            }
        }
    },
    {
        "id": 46,
        "title": "American Roulette",
        "platform": "desktop-and-mobile",
        "type": "tablegame",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/46.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/46.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/46.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/46.gif"
            }
        }
    },
    {
        "id": 47,
        "title": "VIP American Roulette",
        "platform": "desktop-and-mobile",
        "type": "tablegame",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/47.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/47.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/47.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/47.gif"
            }
        }
    },
    {
        "id": 48,
        "title": "VIP French Roulette",
        "platform": "desktop-and-mobile",
        "type": "tablegame",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/48.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/48.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/48.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/48.gif"
            }
        }
    },
    {
        "id": 49,
        "title": "Immersive Roulette",
        "platform": "desktop-and-mobile",
        "type": "tablegame",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/49.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/49.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/49.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/49.gif"
            }
        }
    },
    {
        "id": 50,
        "title": "Pro Roulette",
        "platform": "desktop-and-mobile",
        "type": "tablegame",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/50.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/50.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/50.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/50.gif"
            }
        }
    },
    {
        "id": 51,
        "title": "European Roulette",
        "platform": "desktop-and-mobile",
        "type": "tablegame",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/51.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/51.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/51.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/51.gif"
            }
        }
    },
    {
        "id": 256,
        "title": "Rocketman",
        "platform": "desktop-and-mobile",
        "type": "crashgame",
        "subtype": "casino",
        "enabled": 1,
        "fun_mode": 0,
        "campaigns": 0,
        "vendor": "iconix",
        "vendorGroups": [
            "Iconix"
        ],
        "details": {
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/2/iconix/300x300/256.png",
                "300x300-gif": "https://media.ttfileserver.com/2/iconix/300x300/256.gif",
                "450x345-jpg": "https://media.ttfileserver.com/2/iconix/450x345/256.jpg",
                "450x345-gif": "https://media.ttfileserver.com/2/iconix/450x345/256.gif"
            }
        }
    }
  ]);

  axios
    .get("https://staging-wallet.semper7.net/api/generic/games/list/all", {
      headers: {
        "X-Authorization": sha1(
          "games" + "geniuscasino_stg" + "51aWXUe90TD5XY4U"
        ),
        "X-Operator-Id": "geniuscasino_stg",
      },
    })
    .then((response) => {
      if (response.data) {
        setGames(response.data.games);
      }
    })
    .catch((error) => {
      console.log(error);
    });
    console.log(sha1(
        "games" + "geniuscasino_stg" + "51aWXUe90TD5XY4U"
      ).toString());
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-4">
        {games.map((game) => {
          return (
            <div
              key={game.id}
              className="bg-gray-200 rounded-t-xl overflow-hidden cursor-pointer transition duration-200 ease-in-out hover:shadow-2xl hover:scale-105"
              onClick={() => {
                axios.post('https://casino.geniusfeeds.work/token', {
                    user_id: "611"
                }, {
                    headers: {
                        "X-Authorization": sha1(
                          "token" + "51aWXUe90TD5XY4U"
                        ),
                        "X-Operator-Id": "geniuscasino_stg",
                      },
                }).then((response) => {
                    const baseUrl = "https://staging-wallet-launch1.semper7.net/";
                    const mode = "real";
                    const gameId = game.id;
                    const token = response.data.token;
                    const currency = "EUR";
                    const language = "en_US";
                    const operatorId = "geniuscasino_stg";
                    const homeUrl = "https://casino.geniusfeeds.work";
            
                    const gameUrl = `${baseUrl}?mode=${mode}&game_id=${gameId}&token=${token}&currency=${currency}&language=${language}&operator_id=${operatorId}&home_url=${homeUrl}`;
                    
                    window.open(gameUrl, "_blank");  
                })
              }}
            >
                <img
                    src={game.details.thumbnails["450x345-jpg"]}
                    alt={game.title}
                    className="w-full" 
                />
                <p className="py-3 px-2">
                    <h2 className="font-bold">{game.title}</h2>
                </p>
            </div>
          );
        })}

      </div>
    </div>
  );
}
